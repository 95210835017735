import { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Icon,
  IconButton,
  styled,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Fade,
  Tooltip,
} from "@mui/material";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LaunchIcon from "@mui/icons-material/Launch";
import MDBox from "components/ui/MDBox";
import shopImagePlaceholder from "assets/images/chart.png";
import MDAvatar from "components/ui/MDAvatar";
// import MDInput from "components/ui/MDInput";
// import MDBadge from "components/ui/MDBadge";

// import Breadcrumbs from "components/Breadcrumbs";
// import NotificationItem from "components/Items/NotificationItem";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
  // navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { useAppState } from "state";
import routesList from "routes/routes-list";
import MDTypography from "components/ui/MDTypography";
import colors from "assets/theme/base/colors";
import useCurrentOrgStatus from "hooks/useCurrentOrgStatus";
import OrgCard from "./SelectNavBarOrgs";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  // const [openNotificationMenu, setOpenNotificationMenu] = useState<any>(false);
  // const [openUserMenu, setOpenUserMenu] = useState<any>(false);
  // const route = useLocation().pathname.split("/").slice(1);

  const {
    auth: { logout, user, setLoggedIn },
    currentOrgId,
  } = useAppState();
  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenNotificationMenu = (event: any) => setOpenNotificationMenu(event.currentTarget);
  // const handleCloseNotificationMenu = () => setOpenNotificationMenu(false);

  // const handleOpenUserMenu = (event: any) => setOpenUserMenu(event.currentTarget);
  // const handleCloseUserMenu = () => setOpenUserMenu(false);
  // const removeLocalStorageItems = (key: any) => localStorage && localStorage.removeItem(key);

  const handleClickLogout = () => {
    logout();
    setLoggedIn(false);
    navigate(routesList.auth.login);
    Auth.signOut().catch((e) => {
      console.error(e);
    });
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openOrg, setOpenOrg] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const openUser = Boolean(openOrg);
  const handleClickOrgs = (event: any) => {
    setOpenOrg(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseOrgs = () => {
    setOpenOrg(null);
  };
  // Render the user menu
  // const renderUserMenu = () => (
  //   <Menu
  //     anchorEl={openUserMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openUserMenu)}
  //     onClose={handleCloseUserMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <ActionItem icon={<PowerSettingsNewRounded />} title="Sign out" onClick={handleClickLogout} />
  //   </Menu>
  // );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const Avator = styled(MDAvatar)({
    width: "auto",
    height: "auto",
    img: {
      borderRadius: "50%",
      height: "28px",
      width: "28px",
      objectFit: "cover",
    },
  });
  const iconStyles = {
    width: "3em",
    height: "3em",
  };
  const { suspendedAlert } = useCurrentOrgStatus();
  const helpTo = "https://ci.doc.zapyan.com/";

  const handleExternalLinkClick = (event: any) => {
    event.preventDefault();
    window.open(helpTo, "_blank");
  };

  const lsOrganisation =
    localStorage.getItem("organizations") !== undefined
      ? JSON.parse(localStorage.getItem("organizations"))
      : null;
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon sx={iconsStyle}>{miniSidenav ? "menu_open" : "menu"}</Icon>
          </IconButton>
        </MDBox>
        {suspendedAlert && <MDBox>{suspendedAlert}</MDBox>}
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle}>{miniSidenav ? "menu_open" : "menu"}</Icon>
            </IconButton>
            <MDBox color={light ? "white" : "inherit"} display="flex" alignItems="center">
              <NavLink to={routesList.help.liveChat}>
                <Tooltip title="Live chat">
                  <IconButton size="small" sx={navbarIconButton}>
                    <Icon sx={iconsStyle} style={{ color: colors.dark.focus }}>
                      <ModeCommentIcon />
                    </Icon>
                    <MDBox ml={1}>Live Chat</MDBox>
                  </IconButton>
                </Tooltip>
              </NavLink>
              <NavLink to={routesList.help.docs}>
                <Tooltip title="Help">
                  <IconButton size="medium" sx={navbarIconButton}>
                    <Icon sx={iconsStyle} style={{ color: colors.dark.focus }}>
                      <HelpIcon />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </NavLink>
              <Tooltip title="Dashboards">
                <IconButton
                  size="large"
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={navbarIconButton}
                  onClick={handleClickOrgs}
                >
                  <Avator src={shopImagePlaceholder} size="lg" />
                </IconButton>
              </Tooltip>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={openOrg}
                open={openUser}
                onClick={handleCloseOrgs}
                TransitionComponent={Fade}
              >
                {lsOrganisation?.length > 0 &&
                  lsOrganisation?.map((org: any) => (
                    <MenuItem key={org.org_id}>
                      <OrgCard
                        p={currentOrgId ? 2 : 0}
                        org={{
                          id: org.org_id,
                          name: org.org_id,
                          title: "Shopify",
                        }}
                      />
                    </MenuItem>
                  ))}
              </Menu>
              <Tooltip title="Account">
                <IconButton
                  size="large"
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={navbarIconButton}
                  onClick={handleClick}
                >
                  <Icon sx={iconsStyle} fontSize="inherit" style={{ color: colors.info.main }}>
                    account_circle
                  </Icon>
                </IconButton>
              </Tooltip>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MDTypography
                  sx={{ paddingLeft: "10px" }}
                  variant="text"
                  fontWeight="bold"
                  color="text"
                >
                  Account
                </MDTypography>
                <MenuItem
                  divider
                  onClick={handleClose}
                  sx={{ padding: "2px 3px", pointerEvents: "none" }}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                    <div>
                      <AccountCircleIcon sx={iconStyles} color="info">
                        action
                      </AccountCircleIcon>
                    </div>
                    <div>
                      <MDTypography
                        display="flex"
                        alignItems="center"
                        gap="10px"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                      >
                        {user
                          ? `${user.attributes["custom:first_name"]}` +
                            " " +
                            `${user.attributes["custom:last_name"]}`
                          : null}
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "0.9em" }} color="secondary">
                        {user ? `${user.attributes.email}` : null}
                      </MDTypography>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem divider>
                  <NavLink to={routesList?.userMe}>
                    <MDTypography
                      display="flex"
                      alignItems="center"
                      gap="1rem"
                      sx={{ fontSize: "1em", py: 0.5 }}
                      fontWeight="bold"
                      color="text"
                    >
                      <AccountCircleIcon fontSize="small" />
                      My Account
                    </MDTypography>
                  </NavLink>
                </MenuItem>
                <NavLink to="#" onClick={handleExternalLinkClick}>
                  <MenuItem onClick={handleClose}>
                    <MDTypography
                      display="flex"
                      alignItems="center"
                      gap="10px"
                      variant="button"
                      sx={{ fontSize: "1em" }}
                      fontWeight="bold"
                      color="text"
                    >
                      <LaunchIcon fontSize="small" color="secondary">
                        Documentation
                      </LaunchIcon>{" "}
                      Documentation
                    </MDTypography>
                  </MenuItem>
                </NavLink>
                <MenuItem onClick={handleClickLogout}>
                  <MDTypography
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    sx={{ fontSize: "1em" }}
                    fontWeight="bold"
                    color="text"
                  >
                    <LogoutIcon fontSize="small"> </LogoutIcon> Sign Out
                  </MDTypography>
                </MenuItem>
              </Menu>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenNotificationMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
              {/* {renderNotificationMenu()} */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
