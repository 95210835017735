/* eslint-disable prettier/prettier */
import { useEffect, useState, ReactNode } from "react";

import { useLocation, NavLink } from "react-router-dom";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import _ from "lodash";

import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";

import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavList from "components/Sidenav/SidenavList";
import SidenavItem from "components/Sidenav/SidenavItem";

import SidenavRoot from "components/Sidenav/SidenavRoot";
// import sidenavLogoLabel from "components/Sidenav/styles/sidenav";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: any;
  brandName: string;
  routes: {
    [key: string]:
      | ReactNode
      | string
      | {
          [key: string]:
            | ReactNode
            | string
            | {
                [key: string]: ReactNode | string;
              }[];
        }[];
  }[];
  [key: string]: any;
}

function Sidenav({ color, brand, brandName, routes, ...rest }: Props): JSX.Element {
  const [collapsesMap, setCollapsesMap] = useState<{ [key: string]: string[] }>({});
  const [openCollapse, setOpenCollapse] = useState<string[]>([]);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = useLocation(); // Extract pathname from location
  const pathnameParts = pathname.split("/"); // Split the pathname into parts

  const shouldCollapse = pathnameParts[1] === "help" || pathnameParts[1] === "user";
  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  function buildCollapsesMap(routes: any[], parentCollapses: any[] = []) {
    let collapsesMap: any = {};
    routes.forEach(({ key, collapse }) => {
      if (collapse) {
        collapsesMap[key] = [key];
        collapse.forEach((nestedRoute: any) => {
          if (nestedRoute.route) {
            if (!collapsesMap[nestedRoute.route]) {
              collapsesMap[nestedRoute.route] = [...parentCollapses];
            }
            collapsesMap[nestedRoute.route].push(key);
          }
          if (nestedRoute.collapse) {
            collapsesMap = {
              ...collapsesMap,
              ...buildCollapsesMap([nestedRoute], [...parentCollapses, key]),
            };
            collapsesMap[nestedRoute.key] = [
              ...collapsesMap[nestedRoute.key],
              ...collapsesMap[key],
            ];
            collapsesMap[key] = [...collapsesMap[nestedRoute.key], ...collapsesMap[key]];
          }
        });
      }
    });
    return collapsesMap;
  }

  useEffect(() => {
    const collapsesMap = buildCollapsesMap(routes.filter((r) => r.collapse));
    setCollapsesMap(collapsesMap);
    setOpenCollapse(collapsesMap[pathname] || [] || shouldCollapse);
  }, [shouldCollapse]);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, key, href }: any) =>
      href ? (
        <Link
          key={key || href}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key || route} style={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (routeCollapses: any) =>
    routeCollapses.map(({ name, icon, collapse, route, href, key }: any) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            icon={icon}
            name={name}
            active={collapsesMap[pathname]?.includes(key) ? "isParent" : false}
            open={openCollapse.includes(key)}
            onClick={({ currentTarget }: any) => {
              if (
                openCollapse.includes(key) &&
                currentTarget.classList.contains("MuiListItem-root")
              ) {
                setOpenCollapse(openCollapse.filter((c) => c !== key));
              }
              if (shouldCollapse) {
                _([...openCollapse, key])
                  .intersection(collapsesMap[key])
                  .value();
              } else {
                setOpenCollapse(
                  _([...openCollapse, key])
                    .intersection(collapsesMap[key])
                    .value()
                );
              }
            }}
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key || href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} />
          </Link>
        ) : (
          <NavLink to={route} key={key || route} style={{ textDecoration: "none" }}>
            <SidenavItem icon={icon} color={color} name={name} active={route === pathname} />
          </NavLink>
        );
      }
      return <SidenavList key={key || href || route}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }: any) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key || href}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse name={name} icon={icon} noCollapse={noCollapse} />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key || route}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={route === pathname}
              />
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={collapsesMap[pathname]?.includes(key)}
              open={openCollapse?.includes(key)}
              onClick={() =>
                openCollapse.includes(key)
                  ? setOpenCollapse(openCollapse.filter((c) => c !== key))
                  : setOpenCollapse(
                      _([...openCollapse, key])
                        .intersection(collapsesMap[key])
                        .value()
                    )
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox p={1} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={NavLink}
          to="/"
          display="flex"
          alignItems="center"
          mx={{ xs: "auto" }}
          my={{ xs: 1 }}
          width="100%"
          height="100%"
        >
          {brand && (
            <MDTypography
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              {" "}
              <MDBox
                component="img"
                src={brand?.brandCiLogo?.logo}
                alt="Brand"
                width="60px"
                height="100%"
              />
              {!miniSidenav && (
                <MDBox
                  component="img"
                  src={brand?.brandCiLogo?.text}
                  alt="Brand"
                  width="100%"
                  height="100%"
                />
              )}
            </MDTypography>
          )}
          {/* <MDBox
            width={!brandName && "100%"}
            sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox> */}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

export default Sidenav;
