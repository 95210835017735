const envConfigDev = {
  aws_project_region: "eu-west-2",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_jALOSmp8A",
  aws_user_pools_web_client_id: "7p5sc1henfemkuuntnjtjbprjg",
  oauth: {
    domain: "authenticate-ci-qa.spark.zapyan.com",
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "phone", "profile"],
    redirectSignIn: "https://ci-qa.spark.zapyan.com/",
    redirectSignOut: "https://ci-qa.spark.zapyan.com/",
    responseType: "code",
  },
  aws_appsync_graphqlEndpoint: "https://graphql-qa.spark.zapyan.com/graphql",
  aws_appsync_region: "eu-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_SYMBOLS"],
  },
};
export default envConfigDev;
