import envConfigDev from "./environment/envConfig.dev";
import envConfigStage from "./environment/envConfig.stage";
import envConfigProd from "./environment/envConfig.prod";

const hostName: string = window.location.hostname;

const LOCAL_HOST = "localhost";
const DEV_HOST = "ci-qa.spark.zapyan.com";
const STAGING_HOST = "ci.spark.zapyan.com";
const PRODUCTION_HOST = "ci.zapyan.com";

const environmentMapping: any = {
  [LOCAL_HOST]: envConfigDev,
  [DEV_HOST]: envConfigDev,
  [STAGING_HOST]: envConfigStage,
  [PRODUCTION_HOST]: envConfigProd,
};

const envConfig = environmentMapping[hostName];

export default envConfig;
