/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const hourlyAnalytics =
  /* GraphQL */ `query HourlyAnalytics($id: String!, $from_date: Float, $end_date: Float) {
  hourlyAnalytics(id: $id, from_date: $from_date, end_date: $end_date) {
    id
    date_start_at
    aggregates_type
    currency
    total_add_to_cart
    total_clicks
    total_orders
    total_sales
    total_views
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.HourlyAnalyticsQueryVariables, APITypes.HourlyAnalyticsQuery>;
export const lifetimeAnalytics = /* GraphQL */ `query LifetimeAnalytics($id: String!) {
  lifetimeAnalytics(id: $id) {
    id
    date_start_at
    aggregates_type
    currency
    total_add_to_cart
    total_clicks
    total_orders
    total_sales
    total_views
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.LifetimeAnalyticsQueryVariables, APITypes.LifetimeAnalyticsQuery>;
export const userLookups = /* GraphQL */ `query UserLookups {
  userLookups {
    user_id
    org_id
    user_name
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.UserLookupsQueryVariables, APITypes.UserLookupsQuery>;
export const content = /* GraphQL */ `query Content($page_name: String) {
  content(page_name: $page_name) {
    page_name_status
    order
    page_name
    title
    content_id
    status
    html
    created_at
    updated_at
    icon
    icon_color
    content_date
    content_category
    price
    currency
    button_text
    button_url
    category_order
    content_category_order
    __typename
  }
}
` as GeneratedQuery<APITypes.ContentQueryVariables, APITypes.ContentQuery>;
export const pageCategoryBatch =
  /* GraphQL */ `query PageCategoryBatch($org_id: String!, $label: String, $available: Boolean) {
  pageCategoryBatch(org_id: $org_id, label: $label, available: $available) {
    page_categories {
      public
      tags
      created_at
      updated_at
      integration_type
      id
      org_id
      page_category
      label
      icon
      icon_color
      page_url
      description
      long_description
      linked_field
      integration_type_public_org_id
      published_at
      published
      __typename
    }
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.PageCategoryBatchQueryVariables, APITypes.PageCategoryBatchQuery>;
export const widgetBatch = /* GraphQL */ `query WidgetBatch(
  $org_id: String!
  $label: String
  $available: Boolean
  $type: String
) {
  widgetBatch(
    org_id: $org_id
    label: $label
    available: $available
    type: $type
  ) {
    org_id_public
    public
    org_id
    id
    widget_type
    editable
    label
    block_title
    no_of_rec
    location
    code
    cta_text
    created_at
    updated_at
    published_at
    published
    tags
    icon
    icon_color
    description
    long_description
    location_visible
    no_of_rec_visible
    var {
      category
      name
      type
      hint
      visible
      liquid_name
      list
      __typename
    }
    conf
    __typename
  }
}
` as GeneratedQuery<APITypes.WidgetBatchQueryVariables, APITypes.WidgetBatchQuery>;
export const modelBatch =
  /* GraphQL */ `query ModelBatch($org_id: String!, $label: String, $available: Boolean) {
  modelBatch(org_id: $org_id, label: $label, available: $available) {
    models {
      public
      tags
      pricing_tiers
      created_at
      updated_at
      integration_type
      id
      org_id
      label
      model
      icon
      icon_color
      description
      long_description
      integration_type_public_org_id
      published_at
      published
      __typename
    }
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ModelBatchQueryVariables, APITypes.ModelBatchQuery>;
export const collectionBatch =
  /* GraphQL */ `query CollectionBatch($org_id: String!, $label: String, $archived: Boolean) {
  collectionBatch(org_id: $org_id, label: $label, archived: $archived) {
    org_id
    collection_id
    collection_type
    collection_key
    title
    published_at
    created_at
    updated_at
    source_updated_at
    last_sync_batch_id
    archived
    rules {
      column
      condition
      relation
      __typename
    }
    collection_image
    collection_description
    __typename
  }
}
` as GeneratedQuery<APITypes.CollectionBatchQueryVariables, APITypes.CollectionBatchQuery>;
export const productBatch = /* GraphQL */ `query ProductBatch(
  $org_id: String!
  $label: String
  $archived: Boolean
  $limit: Int
) {
  productBatch(
    org_id: $org_id
    label: $label
    archived: $archived
    limit: $limit
  ) {
    products {
      org_id_product_id
      variant_id
      org_id
      product_id
      vendor
      product_type
      source_created_at
      source_updated_at
      product_key
      published_at
      status
      title
      inventory_quantity
      variant_rank
      price
      sku
      variant_title
      product_image
      created_at
      updated_at
      archived
      tags
      variant_images
      link
      currency_symbol
      description
      full_price
      review_amount
      review_score
      out_of_stock
      __typename
    }
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ProductBatchQueryVariables, APITypes.ProductBatchQuery>;
export const campaignBatch = /* GraphQL */ `query CampaignBatch(
  $org_id: String!
  $category_id: String
  $collection_id: String
  $widget_id: String
  $model_id: String
) {
  campaignBatch(
    org_id: $org_id
    category_id: $category_id
    collection_id: $collection_id
    widget_id: $widget_id
    model_id: $model_id
  ) {
    id
    org_id
    label
    campaign_type
    description
    long_description
    destination {
      category_id
      subcategory
      label
      icon
      icon_color
      description
      __typename
    }
    status
    created_at
    updated_at
    created_by
    updated_by
    activated_at
    design {
      widget_id
      title
      location
      no_of_rec
      cta_text
      label
      icon
      icon_color
      description
      __typename
    }
    target {
      audience
      model_id
      label
      icon
      icon_color
      description
      collection {
        collection_id
        __typename
      }
      __typename
    }
    tags
    icon
    icon_color
    __typename
  }
}
` as GeneratedQuery<APITypes.CampaignBatchQueryVariables, APITypes.CampaignBatchQuery>;
export const pageCategoryUnitary =
  /* GraphQL */ `query PageCategoryUnitary($id: String!, $org_id: String!) {
  pageCategoryUnitary(id: $id, org_id: $org_id) {
    page_category {
      public
      tags
      created_at
      updated_at
      integration_type
      id
      org_id
      page_category
      label
      icon
      icon_color
      page_url
      description
      long_description
      linked_field
      integration_type_public_org_id
      published_at
      published
      __typename
    }
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.PageCategoryUnitaryQueryVariables, APITypes.PageCategoryUnitaryQuery>;
export const widgetUnitary = /* GraphQL */ `query WidgetUnitary($id: String!, $org_id: String!) {
  widgetUnitary(id: $id, org_id: $org_id) {
    org_id_public
    public
    org_id
    id
    widget_type
    editable
    label
    block_title
    no_of_rec
    location
    code
    cta_text
    created_at
    updated_at
    published_at
    published
    tags
    icon
    icon_color
    description
    long_description
    no_of_rec_visible
    location_visible
    var {
      category
      name
      type
      hint
      visible
      liquid_name
      list
      __typename
    }
    conf
    __typename
  }
}
` as GeneratedQuery<APITypes.WidgetUnitaryQueryVariables, APITypes.WidgetUnitaryQuery>;
export const modelUnitary = /* GraphQL */ `query ModelUnitary($id: String!, $org_id: String!) {
  modelUnitary(id: $id, org_id: $org_id) {
    model {
      public
      tags
      pricing_tiers
      created_at
      updated_at
      integration_type
      id
      org_id
      label
      model
      icon
      icon_color
      description
      long_description
      integration_type_public_org_id
      published_at
      published
      __typename
    }
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ModelUnitaryQueryVariables, APITypes.ModelUnitaryQuery>;
export const collectionUnitary =
  /* GraphQL */ `query CollectionUnitary($id: String!, $org_id: String!) {
  collectionUnitary(id: $id, org_id: $org_id) {
    org_id
    collection_id
    collection_type
    collection_key
    title
    published_at
    created_at
    updated_at
    source_updated_at
    last_sync_batch_id
    archived
    rules {
      column
      condition
      relation
      __typename
    }
    collection_image
    collection_description
    __typename
  }
}
` as GeneratedQuery<APITypes.CollectionUnitaryQueryVariables, APITypes.CollectionUnitaryQuery>;
export const productUnitary = /* GraphQL */ `query ProductUnitary($id: String!, $org_id: String!) {
  productUnitary(id: $id, org_id: $org_id) {
    products {
      org_id_product_id
      variant_id
      org_id
      product_id
      vendor
      product_type
      source_created_at
      source_updated_at
      product_key
      published_at
      status
      title
      inventory_quantity
      variant_rank
      price
      sku
      variant_title
      product_image
      created_at
      updated_at
      archived
      tags
      variant_images
      link
      currency_symbol
      description
      full_price
      review_amount
      review_score
      out_of_stock
      __typename
    }
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ProductUnitaryQueryVariables, APITypes.ProductUnitaryQuery>;
export const campaignUnitary =
  /* GraphQL */ `query CampaignUnitary($id: String!, $org_id: String!) {
  campaignUnitary(id: $id, org_id: $org_id) {
    id
    org_id
    label
    campaign_type
    description
    long_description
    destination {
      category_id
      page_category {
        public
        tags
        created_at
        updated_at
        integration_type
        id
        org_id
        page_category
        label
        icon
        icon_color
        page_url
        description
        long_description
        linked_field
        integration_type_public_org_id
        published_at
        published
        __typename
      }
      subcategory
      collection {
        org_id
        collection_id
        collection_type
        collection_key
        title
        published_at
        created_at
        updated_at
        source_updated_at
        last_sync_batch_id
        archived
        rules {
          column
          condition
          relation
          __typename
        }
        collection_image
        collection_description
        __typename
      }
      __typename
    }
    status
    created_at
    updated_at
    created_by
    updated_by
    activated_at
    design {
      widget_id
      conf
      widget {
        org_id_public
        public
        org_id
        id
        widget_type
        editable
        label
        block_title
        no_of_rec
        location
        code
        cta_text
        created_at
        updated_at
        published_at
        published
        tags
        icon
        icon_color
        description
        long_description
        no_of_rec_visible
        location_visible
        var {
          category
          name
          type
          hint
          visible
          liquid_name
          list
          __typename
        }
        conf
        __typename
      }
      title
      location
      no_of_rec
      cta_text
      __typename
    }
    target {
      audience
      model_id
      model {
        public
        tags
        pricing_tiers
        created_at
        updated_at
        integration_type
        id
        org_id
        label
        model
        icon
        icon_color
        description
        long_description
        integration_type_public_org_id
        published_at
        published
        __typename
      }
      collection {
        collection_id
        collection {
          org_id
          collection_id
          collection_type
          collection_key
          title
          published_at
          created_at
          updated_at
          source_updated_at
          last_sync_batch_id
          archived
          rules {
            column
            condition
            relation
            __typename
          }
          collection_image
          collection_description
          __typename
        }
        __typename
      }
      __typename
    }
    tags
    icon
    icon_color
    __typename
  }
}
` as GeneratedQuery<APITypes.CampaignUnitaryQueryVariables, APITypes.CampaignUnitaryQuery>;
export const subscriptionTiers = /* GraphQL */ `query SubscriptionTiers($org_id: ID!) {
  subscriptionTiers(org_id: $org_id) {
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    subscription_tiers {
      id
      integration_type
      status
      key
      created_at
      updated_at
      content {
        order
        page_name
        title
        html
        icon
        icon_color
        content_date
        content_category
        current_subscription_button_text
        category_order
        price
        currency
        button_text
        button_url
        __typename
      }
      billing {
        interval
        amount
        currency_code
        __typename
      }
      limits {
        widget_impressions
        attributed_orders
        interval
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.SubscriptionTiersQueryVariables, APITypes.SubscriptionTiersQuery>;
export const organizationUnitary = /* GraphQL */ `query OrganizationUnitary($org_id: ID!) {
  organizationUnitary(org_id: $org_id) {
    primary_locale
    subscription_tier
    currency
    org_name
    country
    timezone
    money_format
    domain
    created_at
    updated_at
    suspended
    integration_type
    currency_symbol
    __typename
  }
}
` as GeneratedQuery<APITypes.OrganizationUnitaryQueryVariables, APITypes.OrganizationUnitaryQuery>;
export const subscriptionTiersBatch = /* GraphQL */ `query SubscriptionTiersBatch($org_id: ID!) {
  subscriptionTiersBatch(org_id: $org_id) {
    organization {
      org_id
      created_at
      updated_at
      domain
      currency
      money_format
      subscription_tier
      currency_symbol
      __typename
    }
    subscription_tiers {
      id
      integration_type
      status
      key
      created_at
      updated_at
      content {
        order
        page_name
        title
        html
        icon
        icon_color
        content_date
        content_category
        current_subscription_button_text
        category_order
        price
        currency
        button_text
        button_url
        __typename
      }
      billing {
        interval
        amount
        currency_code
        __typename
      }
      limits {
        widget_impressions
        attributed_orders
        interval
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SubscriptionTiersBatchQueryVariables,
  APITypes.SubscriptionTiersBatchQuery
>;
export const collectionLookUpUnitary =
  /* GraphQL */ `query CollectionLookUpUnitary($org_id: ID!, $collection_id: ID!) {
  collectionLookUpUnitary(org_id: $org_id, collection_id: $collection_id) {
    products {
      org_id_product_id
      variant_id
      org_id
      product_id
      vendor
      product_type
      source_created_at
      source_updated_at
      product_key
      published_at
      status
      title
      inventory_quantity
      variant_rank
      price
      sku
      variant_title
      product_image
      created_at
      updated_at
      archived
      tags
      variant_images
      link
      currency_symbol
      description
      full_price
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.CollectionLookUpUnitaryQueryVariables,
    APITypes.CollectionLookUpUnitaryQuery
  >;
export const userUnitary = /* GraphQL */ `query UserUnitary($org_id: ID, $user_id: ID) {
  userUnitary(org_id: $org_id, user_id: $user_id) {
    user_id
    first_name
    last_name
    org_id
    email
    role
    created_by {
      user_id
      email
      first_name
      last_name
      __typename
    }
    updated_by {
      user_id
      email
      first_name
      last_name
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.UserUnitaryQueryVariables, APITypes.UserUnitaryQuery>;
export const userBatch = /* GraphQL */ `query UserBatch($org_id: ID!) {
  userBatch(org_id: $org_id) {
    user_id
    first_name
    last_name
    org_id
    email
    role
    created_by {
      user_id
      email
      first_name
      last_name
      __typename
    }
    updated_by {
      user_id
      email
      first_name
      last_name
      __typename
    }
    created_at
    updated_at
    __typename
  }
}
` as GeneratedQuery<APITypes.UserBatchQueryVariables, APITypes.UserBatchQuery>;
export const appBatch = /* GraphQL */ `query AppBatch($org_id: ID!) {
  appBatch(org_id: $org_id) {
    id
    Label
    app_scope
    app_url
    category
    content {
      description
      documentation_url
      icon
      icon_color
      image_url
      long_description
      order
      __typename
    }
    integration_script
    integration_type
    message
    oauth_url
    status
    __typename
  }
}
` as GeneratedQuery<APITypes.AppBatchQueryVariables, APITypes.AppBatchQuery>;
