const envConfigStage = {
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_2IrffRGYu",
  aws_user_pools_web_client_id: "7u9n3s8l87t229anhhm9gunh7m",
  oauth: {
    domain: "authenticate-ci.spark.zapyan.com",
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "phone", "profile"],
    redirectSignIn: "https://ci.spark.zapyan.com/",
    redirectSignOut: "https://ci.spark.zapyan.com/",
    responseType: "code",
  },
  aws_appsync_graphqlEndpoint: "https://graphql.spark.zapyan.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_SYMBOLS"],
  },
};

export default envConfigStage;
