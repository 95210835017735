import { useState, useEffect } from "react";

import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { API } from "aws-amplify";

import { getItemsLocalStorage, removeLocalStorageItems } from "utils/helpers";
import { CONSTANTS as c } from "utils/constants";

import Sidenav from "components/Sidenav";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import ciBrandLogo from "assets/images/logo/ci-logo.png";
import ciBrandText from "assets/images/logo/ci-text.png";
import brandDark from "assets/images/logo/logo-dark.png";
import IRoute from "routes/IRoute";
import publicRoutes from "routes/public";
import privateRoutes from "routes/private";
import { useAppState } from "state";
import routes from "routes/private/left-sidebar";
import NotFound from "pages/errors/404";
import routesList from "routes/routes-list";
import shopImagePlaceholder from "assets/images/chart.png";
import MDAvatar from "components/ui/MDAvatar";
import { organizationUnitary, subscriptionTiers } from "graphql/queries";
// import WithOrganization from "components/WithOrganization";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    auth: { loggedIn, logout },
    currentOrgId,
    setCurrentOrg,
    setSubscriptions,
    setCurrentSubscription,
  } = useAppState();

  const lsCiLoggedIn = JSON.parse(getItemsLocalStorage(c.CI_LOGGED_IN));
  const sSelectedOrg = sessionStorage.getItem(c.CI_ORG);
  const lsSelectedOrg = localStorage.getItem(c.CI_ORG);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  const ciLoggedIn = lsCiLoggedIn || loggedIn;
  const lsCurrentOrgId: any = currentOrgId || sSelectedOrg || lsSelectedOrg;

  useEffect(() => {
    if (ciLoggedIn && lsCurrentOrgId) {
      setSubscriptions([]);
      setCurrentSubscription(undefined);
      setCurrentOrg(undefined);
      (
        API.graphql({
          query: subscriptionTiers,
          variables: { org_id: lsCurrentOrgId },
        }) as Promise<any>
      ).then(
        ({
          data: {
            subscriptionTiers: { organization, subscription_tiers: data },
          },
        }: any) => {
          setSubscriptions(data);
          setCurrentSubscription({
            ...organization,
            ...data.find((s: any) => s.key === organization.subscription_tier),
          });
        }
      );
      (
        API.graphql({
          query: organizationUnitary,
          variables: { org_id: lsCurrentOrgId },
        }) as Promise<any>
      ).then(({ data: { organizationUnitary: data } }: any) => {
        setCurrentOrg(data);
      });
    }
  }, [ciLoggedIn, lsCurrentOrgId]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (privateRoutes?.length > 0 || publicRoutes?.length > 0) {
      setTimeout(() => {
        setLoading(true);
      }, 500);
    } else {
      setLoading(false);
    }
  }, [privateRoutes, publicRoutes, lsCurrentOrgId]);

  useEffect(() => {
    if (!lsCiLoggedIn) {
      removeLocalStorageItems(c.CI_LOGGED_IN);
      const routeWithPublicRoutes = publicRoutes.find(
        (route) => route?.route === window.location.pathname
      );

      if (routeWithPublicRoutes) {
        navigate(routeWithPublicRoutes.route);
      } else {
        navigate(routesList.auth.login);
        logout();
      }
    }
  }, [lsCiLoggedIn, lsCurrentOrgId]);

  const getRoutes = (allRoutes: IRoute[]): any => [
    ...allRoutes.map((route) => {
      if (route.routes) {
        return (
          <Route path={route.route} element={route.element} key={route.key || route.route}>
            {getRoutes(route.routes)}
          </Route>
        );
      }

      if (route.route) {
        return <Route path={route.route} element={route.element} key={route.key || route.route} />;
      }

      return null;
    }),
    loading && ciLoggedIn && <Route path="*" key="404" element={<NotFound />} />,
  ];
  const brandCiLogo: any = { logo: ciBrandLogo, text: ciBrandText };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && ciLoggedIn && lsCurrentOrgId && (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : { brandCiLogo }}
          brandName="Customer Intelligence"
          routes={[
            {
              type: "collapse",
              key: "org_menu",
              name: lsCurrentOrgId,
              // eslint-disable-next-line jsx-a11y/alt-text
              icon: <MDAvatar src={shopImagePlaceholder} size="sm" />,
              collapse: [
                {
                  name: "Change Dashboard",
                  route: routesList.org.dashbaords,
                },
                {
                  name: "Usage",
                  route: routesList.org.usage,
                },
                {
                  name: "Pricing",
                  route: routesList.org.pricing,
                },
                {
                  name: "User",
                  route: routesList.user,
                },
                {
                  name: "Integration",
                  route: routesList.integration,
                },
              ],
            },
            { type: "divider", key: "org_menu_divider" },
            ...routes,
          ]}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>{getRoutes(ciLoggedIn ? privateRoutes : publicRoutes)}</Routes>
    </ThemeProvider>
  );
}
