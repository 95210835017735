import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import MDBox from "components/ui/MDBox";
import MDButton from "components/ui/MDButton";
import HybridLayout from "layouts/HybridLayout";

function NotFound() {
  return (
    <HybridLayout>
      <MDBox
        display="flex"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        height="100%"
        flexDirection="column"
        gap={2}
      >
        <Typography variant="h1">Error 404</Typography>
        <Typography variant="h2">Erm. Page not found</Typography>
        <Typography variant="h6">
          We suggest you to go to the homepage while we solve this issue
        </Typography>

        <NavLink to="/">
          <MDButton color="info"> Go To Homepage </MDButton>
        </NavLink>
      </MDBox>
    </HybridLayout>
  );
}

export default NotFound;
