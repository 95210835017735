/* eslint-disable no-unused-vars */
import { ReactNode } from "react";

import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";

import MDBox from "components/ui/MDBox";

import PageLayout from "layouts/PageLayout";

import Footer from "layouts/Footer";
import DefaultNavbar from "components/Navbars/DefaultNavbar";

interface Props {
  light?: boolean;
  // coverHeight?: string;
  image: string;
  children: ReactNode;
  sizes?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

function CoverLayout({ image, children, light, sizes }: Props): JSX.Element {
  return (
    <PageLayout>
      <Grid container flexDirection="column" flexWrap="nowrap" height="100%">
        <Grid item>
          <DefaultNavbar routes={[]} transparent light={light} />
          {/* <MDBox display="flex" width="calc(100% - 2rem)" mx={2}>
            <MDBox component="img" src={logoDark} alt="master card" width="20%" mt={1} />
          </MDBox> */}
        </Grid>
        <Grid item flexGrow={1}>
          <MDBox>
            <MDBox
              width="calc(100% - 2rem)"
              // minHeight={coverHeight}
              borderRadius="xl"
              mx={2}
              my={2}
              pt={6}
              pb={28}
              sx={{
                backgroundImage: ({
                  functions: { linearGradient, rgba },
                  palette: { gradients },
                }: Theme) =>
                  image &&
                  `${linearGradient(
                    rgba(gradients.dark.main, 0.4),
                    rgba(gradients.dark.state, 0.4)
                  )}, url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <MDBox mt={{ xs: -20, lg: -16 }} px={1} width="calc(100% - 2rem)" mx="auto">
              <Grid container spacing={1} justifyContent="center">
                <Grid item {...sizes} position="relative">
                  {children}
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </PageLayout>
  );
}

CoverLayout.defaultProps = {
  light: false,
  // coverHeight: "35vh",
  sizes: {
    xs: 11,
    sm: 9,
    md: 5,
    lg: 4,
    xl: 3,
  },
};

export default CoverLayout;
