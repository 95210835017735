/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Card from "@mui/material/Card";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";

import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";

import CoverLayout from "layouts/CoverLayout";

import bgImage from "assets/images/bg-img-3.jpeg";
import routesList from "routes/routes-list";
import FormField from "components/FormField";

import MDLoadingButton from "components/ui/MDLoadingButton";
import PasswordRules from "components/PasswordRules";
import validations from "./schema/validations";
import form from "./schema/form";
import formInitialValues from "./schema/initialValues";

const initialValues = { ...formInitialValues };

function ResetPassword(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [requestedEmail, setRequestedEmail] = useState("");
  const [showPassword, setPasswordVisibility] = useState(false);
  useEffect(() => {
    const { email } = (location.state || {}) as unknown as any;
    if (email) {
      setRequestedEmail(email);
      initialValues.email = email;
    }
  }, [location]);
  const [sumbitError, setSumbitError] = useState("");
  const submitForm = async (values: any, actions: any) => {
    try {
      await Auth.forgotPasswordSubmit(values.email, values.code, values.password);
      actions.resetForm();
      navigate(routesList.auth.login);
    } catch (error: any) {
      setSumbitError(error.message);
      console.error("fAuth.forgotPasswordSubmit:ERROR:", error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    submitForm(values, actions);
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const {
    formId,
    formField: { email, code, password, repeatPassword },
  } = form;

  return (
    <CoverLayout image={bgImage} light>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your {requestedEmail ? "" : "email, "} verification code and password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {sumbitError && (
            <MDBox mb={3}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                {sumbitError}
              </MDTypography>
            </MDBox>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                {!requestedEmail && (
                  <MDBox mb={2}>
                    <FormField
                      type={email.type}
                      label={email.label}
                      name={email.name}
                      value={values.email}
                      error={errors.email && touched.email}
                      success={values.email.length > 0 && !errors.email}
                    />
                  </MDBox>
                )}
                <MDBox mb={2}>
                  <FormField
                    type={code.type}
                    label={code.label}
                    name={code.name}
                    value={values.code}
                    error={errors.code && touched.code}
                    success={values.code.length > 0 && !errors.code}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type={showPassword ? "text" : password.type}
                    label={password.label}
                    name={password.name}
                    value={values.password}
                    error={errors.password && touched.password}
                    success={values.password.length > 0 && !errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Tooltip title="Hide password">
                                <VisibilityOff />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Show password">
                                <Visibility />
                              </Tooltip>
                            )}
                          </IconButton>
                          <PasswordRules />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type={showPassword ? "text" : repeatPassword.type}
                    label={repeatPassword.label}
                    name={repeatPassword.name}
                    value={values.repeatPassword}
                    // placeholder={firstName.placeholder}
                    error={errors.repeatPassword && touched.repeatPassword}
                    success={values.repeatPassword.length > 0 && !errors.repeatPassword}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDLoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    color="info"
                    fullWidth
                  >
                    Submit
                  </MDLoadingButton>
                </MDBox>
              </Form>
            )}
          </Formik>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Remember password?{" "}
              <MDTypography
                component={Link}
                to={routesList.auth.login}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
