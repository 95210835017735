import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import RequestNewPassword from "pages/public/auth/RequestNewPassword";
import ResetPassword from "pages/public/auth/ResetPassword";
import RequestChangePassword from "pages/public/auth/RequestChangePassword";
import ContactUs from "pages/public/ContactUs";
// import FeatureRequest from "pages/public/FeatureRequest";
// import Help from "pages/public/Help";
import IRoute from "routes/IRoute";
import routesList from "routes/routes-list";

const Login = lazy(() => import("pages/public/auth/Login"));
const Register = lazy(() => import("pages/public/auth/Register"));

const {
  auth: { register, login, forgotPassword, resetPassword, changePassword },
  dashboard,
  help,
} = routesList;

const publicRoutes: IRoute[] = [
  {
    route: register,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Register />
      </Suspense>
    ),
  },
  {
    route: login,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Login />
      </Suspense>
    ),
  },
  {
    route: forgotPassword,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <RequestNewPassword />
      </Suspense>
    ),
  },
  {
    route: resetPassword,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    route: changePassword,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <RequestChangePassword />
      </Suspense>
    ),
  },
  {
    route: help.contact,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ContactUs />
      </Suspense>
    ),
  },
  // {
  //   route: help.faq,
  //   element: (
  //     <Suspense fallback={<LoadingScreen />}>
  //       <Help />
  //     </Suspense>
  //   ),
  // },
  // {
  //   route: help.featureRequest,
  //   element: (
  //     <Suspense fallback={<LoadingScreen />}>
  //       <FeatureRequest />
  //     </Suspense>
  //   ),
  // },
  {
    route: dashboard,
    element: <Navigate to={login} />,
  },
  {
    route: "/",
    key: "pub_home",
    element: <Navigate to={login} />,
  },
];

export default publicRoutes;
