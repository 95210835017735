import form from "./form";

const {
  formField: { email, code, password, repeatPassword },
} = form;

const initialValues = {
  [email.name]: "",
  [code.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
};

export default initialValues;
