/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import form from "./form";

const {
  formField: { email },
} = form;

const validations = Yup.object().shape({
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
});

export default validations;
