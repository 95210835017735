import { PropsWithChildren } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/ui/MDBox";

function LoadingScreen({ label, children }: PropsWithChildren<{ label?: string | JSX.Element }>) {
  return (
    <MDBox height="80vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress
        sx={{
          "&.MuiCircularProgress-root": {
            width: "50px !important",
            height: "50px !important",
            color: "#3b3b42",
            marginRight: "10px",
          },
        }}
      />{" "}
      {/* {label}
      {children} */}
    </MDBox>
  );
}

export default LoadingScreen;

LoadingScreen.defaultProps = {
  label: "Loading...",
};
