/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";

import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";

import CoverLayout from "layouts/CoverLayout";

import bgImage from "assets/images/bg-img-4.jpeg";
import routesList from "routes/routes-list";
import FormField from "components/FormField";

import MDLoadingButton from "components/ui/MDLoadingButton";
import validations from "./schema/validations";
import form from "./schema/form";
import initialValues from "./schema/initialValues";

function RequestNewPassword(): JSX.Element {
  const navigate = useNavigate();
  const [sumbitError, setSumbitError] = useState("");

  const submitForm = async (values: any, actions: any) => {
    try {
      await Auth.forgotPassword(values.email);
      actions.resetForm();
      navigate(routesList.auth.resetPassword, {
        state: {
          email: values.email,
        },
      });
    } catch (error: any) {
      setSumbitError(error.message);
      console.error("fAuth.forgotPassword:ERROR:", error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    submitForm(values, actions);
  };

  const {
    formId,
    // eslint-disable-next-line no-unused-vars
    formField: { email },
  } = form;

  return (
    <CoverLayout image={bgImage} light>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Request new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {sumbitError && (
            <MDBox mb={3}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                {sumbitError}
              </MDTypography>
            </MDBox>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <MDBox mb={2}>
                  <FormField
                    type={email.type}
                    label={email.label}
                    name={email.name}
                    value={values.email}
                    error={errors.email && touched.email}
                    success={values.email.length > 0 && !errors.email}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDLoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    color="info"
                    fullWidth
                  >
                    Submit
                  </MDLoadingButton>
                </MDBox>
              </Form>
            )}
          </Formik>
          <MDBox mt={1} mb={1} textAlign="right">
            <MDTypography
              component={Link}
              to={routesList.auth.resetPassword}
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Already Have Code?
            </MDTypography>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Remember password?{" "}
              <MDTypography
                component={Link}
                to={routesList.auth.login}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default RequestNewPassword;
