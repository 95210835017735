/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Link } from "@mui/material";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";

// Declaring props types for BookingCard
interface Props {
  image: string;
  title: string;
  description: string;
  email: string;
  [key: string]: any;
}

function ContactCard({ image, title, description, email }: Props): JSX.Element {
  return (
    <Card style={{ height: "100%" }}>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2} className="card-header">
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="0"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox textAlign="center" pt={3} px={3}>
        <MDTypography variant="h5" fontWeight="regular">
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          {description}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
        <Divider />
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt={0.5}
          pb={3}
          px={3}
          lineHeight={1}
        >
          <MDBox color="text" display="flex" alignItems="center">
            <Icon color="inherit" sx={{ m: 0.5 }} fontSize="large">
              email
            </Icon>
            <Link href={`mailto:${email}`} target="_blank">
              <MDTypography variant="h5" fontWeight="bold" color="text">
                {email}
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ContactCard;
