import { PropsWithChildren } from "react";
import { Card, Grid } from "@mui/material";
import CoverLayout from "layouts/CoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import { useAppState } from "state";
import bgImage from "assets/images/bg-img-1.jpeg";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

function HybridLayout({
  children,
  image,
  transparent,
  light,
  sizes,
}: PropsWithChildren<{ image?: string; transparent?: boolean; light?: boolean; sizes?: any }>) {
  const {
    auth: { loggedIn },
    currentOrgId,
  } = useAppState();

  if (loggedIn && currentOrgId) {
    return (
      <DashboardLayout>
        <Grid container flexWrap="nowrap" flexDirection="column" height="100%">
          <Grid item position="sticky" top={0} zIndex={1}>
            <DashboardNavbar />
          </Grid>
          <Grid item flexGrow={1}>
            {children}
          </Grid>
          <Grid item py={3}>
            <Footer />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }

  return (
    <CoverLayout image={image} sizes={sizes} light={light}>
      {transparent ? children : <Card>{children}</Card>}
    </CoverLayout>
  );
}

export default HybridLayout;

HybridLayout.defaultProps = {
  image: bgImage,
  transparent: false,
  light: false,
  sizes: { xs: 12, lg: 8 },
};
