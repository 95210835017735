/* eslint-disable prettier/prettier */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { CardMedia, styled } from "@mui/material";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React TS components
import { useNavigate } from "react-router-dom";
import MDTypography from "components/ui/MDTypography";
import shopImagePlaceholder from "assets/images/chart.png";
import { useAppState } from "state";
import routesList from "routes/routes-list";
import colors from "assets/theme/base/colors";

interface Props {
  org: {
    id: string;
    name: string;
    title: string;
  };
  [key: string]: any;
}
// const [isValuePresent, setIsValuePresent] = useState(false);

function OrgCard({ org: { id, name }, ...rest }: Props): JSX.Element {
  const { setCurrentOrgId, currentOrgId } = useAppState();
  const [isValuePresent, setIsValuePresent] = useState(false);
  const navigate = useNavigate();
  const setItemsSessionStorage = (name: any, value: any) => sessionStorage.setItem(name, value);
  const setItemsLocalStorage = (name: any, value: any) => localStorage.setItem(name, value);

  const selectDashboard = (org: any) => () => {
    setCurrentOrgId(org);
    setItemsSessionStorage("org", org);
    setItemsLocalStorage("org", org);
    navigate(routesList.dashboard);
  };

  const lsOrganisation = JSON.parse(localStorage.getItem("organizations"));
  const orgIDs = lsOrganisation.map((item: any) => item.org_id);

  useEffect(() => {
    setIsValuePresent(orgIDs?.includes(currentOrgId));
  }, []);

  useEffect(() => {
    // console.log(setCurrentOrgId, "org");
  }, [setCurrentOrgId]);

  const OrgItem = styled("div")({
    width: "auto",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    img: {
      borderRadius: "50%",
      height: "28px",
      width: "28px",
      objectFit: "cover",
    },
  });

  return (
    <div>
      <OrgItem onClick={selectDashboard(id)}>
        <CardMedia
          src={shopImagePlaceholder}
          component="img"
          sx={{
            maxWidth: "100%",
            width: "50px",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />{" "}
        <MDTypography
          variant="h6"
          noWrap
          fontWeight="medium"
          sx={{ fontSize: "12px" }}
          title={name}
          color="secondary"
        >
          {isValuePresent && name === currentOrgId ? (
            <p style={{ color: colors.info.main }}> {name}</p>
          ) : (
            name
          )}
        </MDTypography>
      </OrgItem>
      {/* <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl"></MDBox> */}
    </div>
  );
}

export default OrgCard;
