import { Auth } from "aws-amplify";
import _ from "lodash";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getItemsLocalStorage,
  getItemsSessionStorage,
  setItemsLocalStorage,
  setItemsSessionStorage,
  removeLocalStorageItems,
  removeSessionStorageItems,
} from "utils/helpers";
import { CONSTANTS as c, ERROR_MESSAGE as e } from "utils/constants";
import routesList from "routes/routes-list";

interface AppStateProviderType {
  auth: {
    orgToken?: string;
    loggedIn: boolean;
    loggingIn: boolean;
    user: any;
    [key: string]: any;
  };
  organizations?: any[];
  currentOrgId?: string;
  currentOrg?: any;
  subscriptions?: any[];
  currentSubscription?: any;
  setState: (key: string, value: any) => void;
  [key: string]: any;
}

const AppStateContext = createContext<AppStateProviderType>(null!);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const urlParams = new URLSearchParams(window.location.search);
const errorDescription = urlParams.get("error_description");

function AppStateProvider({ children }: PropsWithChildren<{}>) {
  const query = useQuery();
  const navigate = useNavigate();

  const [socialAuthError, setSocialAuthError] = useState<string>(null);

  const orgToken = query.get(c.TOKEN) || getItemsSessionStorage(c.ORG_TOKEN);

  useEffect(() => {
    if (errorDescription) {
      setSocialAuthError(e.USER_EXIST_ERROR_MSG);
    }
  }, [errorDescription]);

  useEffect(() => {
    if (orgToken) {
      setItemsSessionStorage(c.ORG_TOKEN, orgToken);
    }
  }, [orgToken]);

  const [state, _setState] = useState<AppStateProviderType>({
    // @ts-ignore
    auth: {
      orgToken,
    },
  });

  const setState = useCallback(
    (key: string, value: any) => {
      _setState((state) => {
        _.set(state, key, value);
        return { ...state };
      });
    },
    [_setState]
  );

  const setLoggingIn = (val: boolean) => {
    setState("auth.loggingIn", val);
  };

  const setLoggedIn = (val: boolean) => {
    setState("auth.loggedIn", val);
  };

  const setUser = (val: any) => {
    setState("auth.user", val);
  };

  const setOrganizations = (val: any) => {
    setState("organizations", val);
  };

  const setCurrentOrgId = (val: any) => {
    if (val !== null) {
      setItemsSessionStorage(c.CI_ORG, val);
    } else {
      sessionStorage.removeItem(c.CI_ORG);
    }
    setState("currentOrgId", val);
  };

  const setCurrentOrg = (val: any) => {
    setState("currentOrg", val);
  };

  const setSubscriptions = (val: any) => {
    setState("subscriptions", val);
  };

  const setCurrentSubscription = (val: any) => {
    setState("currentSubscription", val);
  };

  const logout = () => {
    // @ts-ignore
    _setState({ auth: {} });
    setLoggedIn(false);
    setLoggingIn(false);
    localStorage.clear();
    sessionStorage.clear();
    navigate(routesList.auth.login);
  };

  const sSelectedOrg = getItemsSessionStorage(c.CI_ORG);
  const lsCurrentOrgId = getItemsLocalStorage(c.CI_ORG);
  const lsCiLoggedIn = JSON.parse(getItemsLocalStorage(c.CI_LOGGED_IN));

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (sSelectedOrg !== null) {
        setItemsLocalStorage(c.CI_ORG, sSelectedOrg);
      }
    }
  };

  useEffect(() => {
    setLoggingIn(true);
    Auth.currentAuthenticatedUser()
      .then((u: any) => {
        setSocialAuthError(null);
        setUser(u);
        setLoggedIn(lsCiLoggedIn);
        if (sSelectedOrg !== null && lsCiLoggedIn) {
          document.addEventListener("visibilitychange", handleVisibilityChange);
        }
        if (sSelectedOrg === null && lsCurrentOrgId !== null && lsCiLoggedIn) {
          setItemsSessionStorage(c.CI_ORG, lsCurrentOrgId);
          setCurrentOrgId(lsCurrentOrgId);
        } else {
          setCurrentOrgId(sSelectedOrg);
        }
      })
      .catch((e: any) => {
        setLoggedIn(false);
        removeLocalStorageItems(c.CI_ORG);
        removeSessionStorageItems(c.CI_ORG);
        removeLocalStorageItems(c.CI_LOGGED_IN);
        removeSessionStorageItems(c.ORGANIZATIONS);
      })
      .finally(() => {
        setLoggingIn(false);
      });
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [lsCiLoggedIn, lsCurrentOrgId, sSelectedOrg]);

  const { auth, ...restState } = state;
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: AppStateProviderType = {
    auth: {
      ...auth,
      setLoggingIn,
      setLoggedIn,
      setUser,
      logout,
      socialAuthError,
    },
    ...restState,
    setOrganizations,
    setCurrentOrgId,
    setCurrentOrg,
    setSubscriptions,
    setCurrentSubscription,
    setState,
  };
  return <AppStateContext.Provider value={contextValue}>{children}</AppStateContext.Provider>;
}

export default AppStateProvider;

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState should be used inside the AppStateProvider.");
  }
  return context;
}
