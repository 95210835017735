export const getItemsSessionStorage = (name: any) => sessionStorage && sessionStorage.getItem(name);

export const setItemsSessionStorage = (name: any, value: any) =>
  sessionStorage.setItem(name, value);

export const setItemsLocalStorage = (name: any, value: any) => localStorage.setItem(name, value);

export const removeSessionStorageItems = (key: any) => sessionStorage.removeItem(key);

export const removeLocalStorageItems = (key: any) => localStorage.removeItem(key);

export const getItemsLocalStorage = (name: any) => localStorage && localStorage.getItem(name);

export const ToLowerCase = (text: any) => (typeof text === "string" ? text?.toLowerCase() : "");

export const checkArrayType = (value: any): boolean => Array.isArray(value);

export const checkStringType = (value: any): boolean => typeof value === "string";

export const checkObjectType = (value: any): boolean => typeof value === "object";

export const checkStringLength = (str: string) => typeof str === "string" && str?.length > 0 && str;

export const capitalize = (s: any) =>
  s && checkStringType(s) ? s.charAt(0).toUpperCase() + ToLowerCase(s.slice(1)) : s;

export const capitalizeEveryWord = (text: string) =>
  text &&
  text
    .split(" ")
    ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const convertToSlug = (input: string) =>
  checkStringType(input) && ToLowerCase(input?.trim())?.replace(/\s+/g, "-");

export const convertSpaceToUnderscore = (input: string) =>
  ToLowerCase(input?.trim()).replace(/\s+/g, "_");

export const replaceUnderscoresToSpace = (input: string) =>
  input?.replace(/_/g, " ").split("_").join(" ");

export const removeWhiteSpaces = (text: string) => text?.replace(/\s/g, "");

export const trimStart = (text: string) => checkStringType(text) && text?.trimStart();

export const trimExtraSpaces = (text: string) =>
  checkStringType(text) && text?.replace(/\s+/g, " ").trimEnd();

export const hasEmptyArrayObject: any = (arr: any) =>
  arr?.length > 0 && Object.keys(arr[0])?.length === 0;

export const hasObjectValue: any = (obj: any) =>
  checkObjectType(obj) && obj && Object.keys(obj)?.length !== 0;

export const removeHtmlHeadInjection = (id: string) => {
  const injectedItem = document.querySelectorAll(`#${id}`);
  if (injectedItem !== null) {
    injectedItem.forEach((script: any) => {
      script.parentNode.removeChild(script);
    });
  }
};

export const generateUniqueKey = () => {
  const prefix = "customKeyPrefix";
  const timestamp = new Date().getTime();
  const randomPart = Math.random().toString(36).substring(2, 10);
  return `${prefix}-${timestamp}-${randomPart}`;
};

export const sortDefault = (a: any, b: any) => {
  const dateA = new Date(a.created_at).getTime();
  const dateB = new Date(b.created_at).getTime();
  return dateB - dateA;
};

export const dateFormat = (date: any) => {
  const formatDateTime = new Date(date?.toString().length === 10 ? date * 1000 : date);
  return formatDateTime.toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
    hour12: true,
  });
};

export enum eStatus {
  // eslint-disable-next-line no-unused-vars
  ACTIVE = "active",
  // eslint-disable-next-line no-unused-vars
  STOPPED = "stopped",
}
