import MDAlert from "components/ui/MDAlert";
import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import { useAppState } from "state";

function useCurrentOrgStatus() {
  const { currentOrg } = useAppState();
  const alert = currentOrg?.suspended ? (
    <MDAlert color="primary">
      <MDBox color="light">
        All services have been suspended! You have reached your usage limit.
        <MDTypography
          ml={1}
          variant="body2"
          component="a"
          href="https://ci.doc.zapyan.com/getting-started/your-account/account-usage#h.bsdidizbardn"
          target="_blank"
          color="dark"
        >
          <strong>[View More]</strong>
        </MDTypography>
      </MDBox>
    </MDAlert>
  ) : null;
  return { currentOrg, suspendedAlert: alert };
}

export default useCurrentOrgStatus;
