/* eslint-disable no-unused-vars */
import { ErrorMessage, Field } from "formik";

import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import MDInput from "components/ui/MDInput";

interface Props {
  label: string;
  name: string;
  [key: string]: any;
  variant?: string;
}

function FormField({ label, name, variant, ...rest }: Props): JSX.Element {
  return (
    <MDBox mb={1.5}>
      <Field
        {...rest}
        name={name}
        as={MDInput}
        variant={variant}
        label={label}
        fullWidth
        sx={{
          "& .MuiInputLabel-root": {
            padding: "2px",
          },
        }}
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}
FormField.defaultProps = {
  variant: "standard",
};
export default FormField;
