import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { Amplify } from "aws-amplify";
import { MaterialUIControllerProvider } from "context";
import App from "App";
import AppStateProvider from "state";

import awsExports from "./aws-exports";
import env from "./envConfig";

import "assets/css/index.css";

type AwsExportsKeys = keyof typeof awsExports;

const keysToCheck = [
  "aws_appsync_region",
  "aws_project_region",
  "aws_cognito_region",
  "oauth",
  "aws_user_pools_id",
  "aws_user_pools_web_client_id",
  "aws_appsync_authenticationType",
  "aws_cognito_password_protection_settings",
  "aws_appsync_graphqlEndpoint",
];

const awsExportsKeys = keysToCheck as AwsExportsKeys[];

awsExportsKeys.forEach((key) => {
  if (
    !(key in awsExports) ||
    [
      "aws_appsync_region",
      "aws_project_region",
      "aws_user_pools_web_client_id",
      "aws_user_pools_id",
      "aws_cognito_region",
      "oauth",
    ].includes(key)
  ) {
    awsExports[key] = env[key];
  }
});

Amplify.configure(awsExports);

const appRoot = ReactDOM.createRoot(document.getElementById("root"));

appRoot.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
