import React from "react";
import * as Yup from "yup";
import { InfoRounded } from "@mui/icons-material";
import { IconButton, Popover } from "@mui/material";
// import awsConfig from "aws-exports";
import awsConfig from "envConfig";

const {
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: length,
    passwordPolicyCharacters: rules,
  },
} = awsConfig;

const REQUIRES_NUMBERS = rules.indexOf("REQUIRES_NUMBERS") !== -1;
const REQUIRES_SYMBOLS = rules.indexOf("REQUIRES_SYMBOLS") !== -1;
const REQUIRES_UPPERCASE = rules.indexOf("REQUIRES_UPPERCASE") !== -1;
const REQUIRES_LOWERCASE = rules.indexOf("REQUIRES_LOWERCASE") !== -1;

function PasswordRules() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="show password policy"
        aria-owns={open ? "password-policy" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handleClick}
        edge="end"
      >
        <InfoRounded />
      </IconButton>
      <Popover
        id="password-policy"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ul>
          {length && <li>Minimum length {length} characters</li>}
          {REQUIRES_NUMBERS && <li>Contains at least 1 number</li>}
          {REQUIRES_SYMBOLS && <li>Contains at least 1 special character</li>}
          {REQUIRES_UPPERCASE && <li>Contains at least 1 uppercase letter</li>}
          {REQUIRES_LOWERCASE && <li>Contains at least 1 lowercase letter</li>}
        </ul>
      </Popover>
    </div>
  );
}

export default PasswordRules;

let yupRule = Yup.string();

if (length) {
  yupRule = yupRule.min(length, `Password is too short. Should be ${length} characters minimum.`);
}

if (REQUIRES_NUMBERS) {
  yupRule = yupRule.matches(/[0-9]+/, `Password should contain at least 1 number`);
}

if (REQUIRES_SYMBOLS) {
  yupRule = yupRule.matches(
    /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]+/,
    `Password should contain at least 1 special character`
  );
}

if (REQUIRES_UPPERCASE) {
  yupRule = yupRule.matches(/[A-Z]+/, `Password should contain at least 1 uppercase letter`);
}

if (REQUIRES_LOWERCASE) {
  yupRule = yupRule.matches(/[a-z]+/, `Password should contain at least 1 lowercase letter`);
}

export const passwordRule = yupRule;
