const form = {
  formId: "request-new-password-form",
  formField: {
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
  },
};

export default form;
