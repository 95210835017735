const envConfigProd = {
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_ky3VA4Zp0",
  aws_user_pools_web_client_id: "tvdp63omk1ref4kkamoahmrar",
  oauth: {
    domain: "authenticate.ci.zapyan.com",
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "profile"],
    redirectSignIn: "https://ci.zapyan.com/",
    redirectSignOut: "https://ci.zapyan.com/",
    responseType: "code",
  },
  aws_appsync_graphqlEndpoint: "https://graphql.ci.zapyan.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_SYMBOLS"],
  },
};
export default envConfigProd;
