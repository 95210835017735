// @mui icons
import Icon from "@mui/material/Icon";
import routesList from "routes/routes-list";

// Images

const { dashboard, campaign, help, explorer } = routesList;

const routes = [
  {
    type: "collapse",
    noCollapse: true,
    name: "Dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: dashboard,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Campaign",
    icon: <Icon fontSize="medium">campaign</Icon>,
    route: campaign,
  },
  {
    type: "collapse",
    name: "Explorer",
    key: "explorer_panel",
    icon: <Icon fontSize="medium">explorer_panel</Icon>,
    collapse: [
      {
        name: "Recommendation",
        route: explorer.recommendation,
      },
      {
        name: "Collection",
        route: explorer.collection,
      },
      {
        name: "Widget",
        route: explorer.widget,
      },
      {
        name: "Page",
        route: explorer.pageCategory,
      },
      {
        name: "Product",
        route: explorer.product,
      },
    ],
  },
  {
    type: "collapse",
    name: "Help",
    key: "help_panel",
    icon: <Icon fontSize="medium">help_outlined</Icon>,
    collapse: [
      {
        name: "Documentation",
        route: help.docs,
      },
      {
        name: "FAQ",
        route: help.faq,
      },
      {
        name: "Release Notes",
        route: help.releaseNotes,
      },
      // {
      //   name: "Feature Request",
      //   route: help.featureRequest,
      // },
      {
        name: "Contact Us",
        route: help.contact,
      },
      {
        name: "Live Chat",
        route: help.liveChat,
      },
    ],
  },
];

export default routes;
