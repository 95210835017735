import form from "./form";

const {
  formField: { password, repeatPassword },
} = form;

const initialValues = {
  [password.name]: "",
  [repeatPassword.name]: "",
};

export default initialValues;
