import { Card, Grid } from "@mui/material";
import ContactCard from "components/Cards/ContactCard";
import HybridLayout from "layouts/HybridLayout";
import salesImage from "assets/images/hands.jpg";
import supportImage from "assets/images/people.jpg";
import pageImage from "assets/images/comments.png";
import MDAvatar from "components/ui/MDAvatar";
import MDTypography from "components/ui/MDTypography";
import { useAppState } from "state";

function ContactUs() {
  const { currentOrgId } = useAppState();
  const loggedIn = !!currentOrgId;
  return (
    <HybridLayout transparent>
      <Card>
        <Grid container spacing={3} alignItems="center" px={3} py={1}>
          <Grid item display={{ xs: "none", lg: "block" }}>
            <MDAvatar src={pageImage} size="lg" shadow="sm" />
          </Grid>
          <Grid item>
            <MDTypography variant="h4">Contact Us</MDTypography>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={3} mt={2} justifyContent="center">
        <Grid item xs={12} sm={6} lg={loggedIn ? 4 : 6} xl={loggedIn ? 3 : 5}>
          <ContactCard
            title="Talk to Sales"
            description="Interested in new exciting feature and ennhancing our capabilities? Please email our sales team."
            image={salesImage}
            email="sales@zapyan.com"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={loggedIn ? 4 : 6} xl={loggedIn ? 3 : 5} mt={{ xs: 3, sm: 0 }}>
          <ContactCard
            title="Contact Customer Support"
            description="Sometimes we all need help, Dont worry, we are here for you :)"
            image={supportImage}
            email="support@zapyan.com"
          />
        </Grid>
      </Grid>
    </HybridLayout>
  );
}

export default ContactUs;
