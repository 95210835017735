import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import IRoute from "routes/IRoute";
import routesList from "routes/routes-list";

const Campaign = lazy(() => import("pages/private/admin/Campaign"));
const NewCampaign = lazy(() => import("pages/private/admin/Campaign/NewCampaign"));
const Dashboard = lazy(() => import("pages/private/admin/Dashboard"));
const User = lazy(() => import("pages/private/admin/UserManagement/User"));
const UserMe = lazy(() => import("pages/private/admin/UserManagement/UserMe"));
const NewUser = lazy(() => import("pages/private/admin/UserManagement/newUser"));
const Integration = lazy(() => import("pages/private/Integration"));
const ContactUs = lazy(() => import("pages/public/ContactUs"));
const Docs = lazy(() => import("pages/private/Documentation"));
const ReleaseNotes = lazy(() => import("pages/private/ReleaseNotes"));
// const FeatureRequest = lazy(() => import("pages/public/FeatureRequest"));
const Help = lazy(() => import("pages/public/Help"));
const Pricing = lazy(() => import("pages/private/Pricing"));
const Usage = lazy(() => import("pages/private/Usage"));
const WithOrganization = lazy(() => import("components/WithOrganization"));
const SelectOrganization = lazy(() => import("components/WithOrganization/SelectOrganization"));
const Recommendation = lazy(() => import("pages/private/admin/Explorer/Recommendation"));
const RecommendationItemDetails = lazy(
  () => import("pages/private/admin/Explorer/Recommendation/RecommendationItemDetails")
);
const Collection = lazy(() => import("pages/private/admin/Explorer/Collection"));
const NewCollection = lazy(() => import("pages/private/admin/Explorer/Collection/NewCollection"));

const Widget = lazy(() => import("pages/private/admin/Explorer/Widget"));
const NewWidget = lazy(() => import("pages/private/admin/Explorer/Widget/NewWidget"));

const PageCategory = lazy(() => import("pages/private/admin/Explorer/PageCategory"));
const PageCategoryItemDetails = lazy(
  () => import("pages/private/admin/Explorer/PageCategory/PageCategoryItemDetails")
);

const Product = lazy(() => import("pages/private/admin/Explorer/Product"));
const ProductDetails = lazy(() => import("pages/private/admin/Explorer/Product/ProductDetails"));

const LiveChat = lazy(() => import("pages/private/LiveChat"));

const {
  auth: { register, login },
  org,
  dashboard,
  user,
  userMe,
  newUser,
  integration,
  campaign,
  newCampaign,
  newWidget,
  explorer,
  help,
  newCollection,
} = routesList;

const privateRoutes: IRoute[] = [
  {
    route: org.dashbaords,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <WithOrganization>
          <SelectOrganization />
        </WithOrganization>
      </Suspense>
    ),
  },
  {
    route: org.pricing,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Pricing />
      </Suspense>
    ),
  },
  {
    route: org.usage,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Usage />
      </Suspense>
    ),
  },
  {
    route: dashboard,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    route: user,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <User />
      </Suspense>
    ),
  },
  {
    route: userMe,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <UserMe />
      </Suspense>
    ),
  },
  {
    route: `${user}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewUser />
      </Suspense>
    ),
  },
  {
    route: newUser,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewUser />
      </Suspense>
    ),
  },
  {
    route: campaign,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Campaign />
      </Suspense>
    ),
  },
  {
    route: integration,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Integration />
      </Suspense>
    ),
  },
  {
    route: newCampaign,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewCampaign />
      </Suspense>
    ),
  },

  {
    route: `${campaign}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewCampaign />
      </Suspense>
    ),
  },
  {
    route: explorer.recommendation,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Recommendation />
      </Suspense>
    ),
  },
  {
    route: `${explorer.recommendation}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <RecommendationItemDetails />
      </Suspense>
    ),
  },
  {
    route: explorer.collection,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Collection />
      </Suspense>
    ),
  },
  {
    route: `${explorer.collection}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewCollection />
      </Suspense>
    ),
  },
  {
    route: explorer.widget,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Widget />
      </Suspense>
    ),
  },
  {
    route: `${explorer.widget}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewWidget />
        {/* <WidgetItemDetails /> */}
      </Suspense>
    ),
  },
  {
    route: newWidget,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewWidget />
      </Suspense>
    ),
  },
  {
    route: newCollection,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NewCollection />
      </Suspense>
    ),
  },
  {
    route: explorer.pageCategory,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <PageCategory />
      </Suspense>
    ),
  },

  {
    route: `${explorer.pageCategory}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <PageCategoryItemDetails />
      </Suspense>
    ),
  },
  {
    route: explorer.product,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Product />
      </Suspense>
    ),
  },
  {
    route: `${explorer.product}/:id`,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ProductDetails />
      </Suspense>
    ),
  },
  {
    route: help.docs,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Docs />
      </Suspense>
    ),
  },
  {
    route: help.faq,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Help />
      </Suspense>
    ),
  },
  {
    route: help.releaseNotes,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ReleaseNotes />
      </Suspense>
    ),
  },
  // {
  //   route: help.featureRequest,
  //   element: (
  //     <Suspense fallback={<LoadingScreen />}>
  //       <FeatureRequest />
  //     </Suspense>
  //   ),
  // },
  {
    route: help.contact,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ContactUs />
      </Suspense>
    ),
  },
  {
    route: help.liveChat,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LiveChat />
      </Suspense>
    ),
  },
  {
    route: login,
    element: <Navigate to={dashboard} />,
  },
  {
    route: register,
    element: <Navigate to={dashboard} />,
  },
  {
    route: "/",
    key: "pvt_home",
    element: <Navigate to={dashboard} />,
  },
];

export default privateRoutes;
