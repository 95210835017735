/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";

import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Card from "@mui/material/Card";
import { Form, Formik } from "formik";

import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";

import CoverLayout from "layouts/CoverLayout";

import bgImage from "assets/images/bg-img-3.jpeg";
import routesList from "routes/routes-list";
import { useAppState } from "state";

import FormField from "components/FormField";

import MDLoadingButton from "components/ui/MDLoadingButton";
import PasswordRules from "components/PasswordRules";
import validations from "./schema/validations";
import form from "./schema/form";
import formInitialValues from "./schema/initialValues";

const initialValues = { ...formInitialValues };

function ResetPassword(): JSX.Element {
  const {
    auth: { setLoggedIn, setUser },
  } = useAppState();

  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setPasswordVisibility] = useState(false);

  const { username, existingPassword } = (location.state || {}) as unknown as any;

  const [submitError, setSubmitError] = useState("");
  const setItemsLocalStorage = (name: any, value: any) => localStorage.setItem(name, value);

  const submitForm = async (values: any, actions: any) => {
    try {
      const newPassword: any = values.password;
      const user = await Auth.signIn(username, existingPassword);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const loggedInUser = await Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword // the new password
        );
        if (loggedInUser) {
          setLoggedIn(true);
          setItemsLocalStorage("ciLoggedIn", JSON.stringify(true));
          setUser(loggedInUser);
          navigate(routesList.org.dashbaords);
        }
      }
    } catch (error: any) {
      setSubmitError(error.message);
      console.error("Error completing new password challenge:", error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    submitForm(values, actions);
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const {
    formId,
    formField: { password, repeatPassword },
  } = form;

  return (
    <CoverLayout image={bgImage} light>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your new password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {submitError && (
            <MDBox mb={3}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                {submitError}
              </MDTypography>
            </MDBox>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <MDBox mb={2}>
                  <FormField
                    type={showPassword ? "text" : password.type}
                    label={password.label}
                    name={password.name}
                    value={values.password}
                    error={errors.password && touched.password}
                    success={values.password.length > 0 && !errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Tooltip title="Hide password">
                                <VisibilityOff />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Show password">
                                <Visibility />
                              </Tooltip>
                            )}
                          </IconButton>
                          <PasswordRules />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type={showPassword ? "text" : repeatPassword.type}
                    label={repeatPassword.label}
                    name={repeatPassword.name}
                    value={values.repeatPassword}
                    // placeholder={firstName.placeholder}
                    error={errors.repeatPassword && touched.repeatPassword}
                    success={values.repeatPassword.length > 0 && !errors.repeatPassword}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDLoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    color="info"
                    fullWidth
                  >
                    Submit
                  </MDLoadingButton>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
