/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import { passwordRule } from "components/PasswordRules";
import form from "./form";

const {
  formField: { password, repeatPassword },
} = form;

const validations = Yup.object().shape({
  [password.name]: passwordRule.required(password.errorMsg),
  [repeatPassword.name]: passwordRule
    .required(repeatPassword.errorMsg)
    .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
});

export default validations;
