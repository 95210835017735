export const CONSTANTS = {
  ACTION: "action",
  ACTIVATE: "Activate",
  ACTIVE: "active",
  ADD_MODE: "add",
  ARCHIVE: "Archive",
  ARCHIVED: "archived",
  AUDIENCE: "audience",
  BLOCK_TITLE: "blockTitle",
  BOOLEAN: "boolean",
  BOOLEAN_FALSE: false,
  BOOLEAN_TRUE: true,
  BORDER_WIDTH: "border_width",
  CAMPAIGN_ACTIVATED_STATUS: "campaignActivatedStatus",
  CAMPAIGN_NAME: "campaignName",
  CAMPAIGN_EDITABLE: "campaignEditable",
  CAMPAIGN_UPDATED_ID: "campaignUpdatedId",
  CATEGORY: "category",
  CI_CUSTOM_COLLECTION_TYPE: "ci_custom_collection",
  CI_LOGGED_IN: "ciLoggedIn",
  CI_MULTI_PARAM_RENDER: "ci_multi_param_render",
  CI_ORG: "org",
  CI_PARAM_RENDER: "ci_param_render",
  CI_RENDER: "ci_render",
  CODE: "code",
  COLLECTIONS: "collections",
  COLLECTION_DESCRIPTION: "collectionDescription",
  COLLECTION_NAME: "collectionName",
  COLLECTION_UPDATED_ID: "collectionUpdatedId",
  COLOR_FIELD: "colorField",
  COLOR_PICKER: "color_picker",
  CONFIG: "config",
  CONFIGURATION: "configuration",
  CONNECT: "Connect",
  CONNECTED: "connected",
  CREATED: "Created",
  CTA_TEXT: "ctaText",
  CUSTOM_TABLE_LAYOUT: true,
  DARK: "dark",
  DEACTIVATE: "Deactivate",
  DE_ACTIVATE: "De-Activate",
  DEFAULT_VALUES: "defaultValues",
  DESIGNER: "designer",
  DRAFT: "draft",
  DUPLICATE: "Duplicate",
  EDIT_MODE: "edit",
  EMPTY_VALUE: "",
  ENUM: "enum",
  ENUM_FIELD: "enumField",
  ERROR: "error",
  EVERYONE: "everyone",
  FONT: "font",
  GENERAL: "general",
  HINT_TEXT: "hintText",
  INFO: "info",
  INJECTED_SCRIPT_ID: "code-editor-link",
  INJECTED_STYLE_ID: "my-widget-style",
  IS_FORM_TOUCHED: "isFormTouched",
  IS_PUBLISHED_FORM: "isPublishedForm",
  IS_SWITCH_STATUS: "isSwitchStatus",
  LAYOUT: "layout",
  LINKED_FIELD: "product_collection_",
  LIST: "list",
  LIST_KEY: "listKey",
  LIST_VALUE: "listValue",
  LOCATION_VISIBLE: "location_visible",
  LONG_DESCRIPTION: "longDescription",
  MOBILE_VIEW_SELECTED: "mobileViewSelected",
  MORE_INFO: "More info",
  NO_OF_REC_VISIBLE: "no_of_rec_visible",
  NO_PRODUCTS: "noOfProducts",
  NUMBER: "number",
  NUMBER_FIELD: "numberField",
  ORG_TOKEN: "org_token",
  ORGANIZATIONS: "organizations",
  PAGE_CATEGORY: "pageCategory",
  PAGE_POSITION: "pagePosition",
  PAGE_SUB_CATEGORY: "pageSubCategory",
  PREVIEW: "preview",
  PRODUCTS: "products",
  PRODUCT_COLLECTION: "productCollection",
  PUBLISH: "Publish",
  PUBLISHED: "published",
  READ_ONLY_DISABLED: false,
  READ_ONLY_ENABLED: true,
  RECOMMENDATION_ALGORITHM: "recommendationAlgorithm",
  RECONNECT: "Reconnect",
  SELECT_MULTIPLE_ITEMS: true,
  SELECT_SINGLE_ITEM: false,
  SHORT_DESCRIPTION: "shortDescription",
  STOPPED: "stopped",
  STRING: "string",
  STRING_FIELD: "stringField",
  STYLE: "style",
  STYLING: "styling",
  SUCCESS: "success",
  SWITCH_FIELD: "switchField",
  TABLE: "table",
  TEXT: "text",
  TOP_NAV: "topNav",
  TOKEN: "token",
  TYPE: "type",
  UNAUTHORIZED: "unauthorised",
  UPDATED: "Updated",
  VARIABLES: "variables",
  VARIABLE_NAME: "variableName",
  VALUE: "value",
  VISIBLE: "visible",
  VIEW_MORE: "View More",
  WIDGET: "widget",
  WIDGET_NAME: "widgetName",
  WIDGET_UPDATED_ID: "widgetUpdateId",
};

export const ERROR_MESSAGE = {
  USER_EXIST_ERROR_MSG: "The account already exist, Please login using your credentials.",
};
