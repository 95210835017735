const routesList = {
  auth: {
    register: `/register`,
    login: `/login`,
    forgotPassword: "/password/request",
    resetPassword: "/password/reset",
    changePassword: "/password/new",
  },
  org: {
    usage: `/org/usage`,
    pricing: `/org/pricing`,
    dashbaords: `/org/dashboards`,
  },
  dashboard: `/dashboard`,
  user: "/user",
  userMe: `/user/me`,
  newUser: "/user/new-user",
  integration: "/org/integration",
  campaign: `/campaign`,
  newCampaign: `/new-campaign`,
  newWidget: `/explorer/widget/new-widget`,
  newCollection: `/explorer/collection/new-collection`,
  help: {
    index: `/help`,
    docs: `/help/docs`,
    faq: `/help/faq`,
    liveChat: `/help/live-chat`,
    releaseNotes: `/help/release_notes`,
    // featureRequest: `/help/feature-request`,
    contact: `/help/contact`,
  },
  explorer: {
    recommendation: "/explorer/recommendation",
    collection: "/explorer/collection",
    widget: "/explorer/widget",
    pageCategory: "/explorer/page-category",
    product: "/explorer/product",
  },
};

export default routesList;
