const form = {
  formId: "reset-password-form",
  formField: {
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    code: {
      name: "code",
      label: "Verification Code",
      type: "text",
      errorMsg: "Verification code is required.",
    },
    password: {
      name: "password",
      label: "New Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
  },
};

export default form;
